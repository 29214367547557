// ------------------------------
// 上へボタン
// ベース：hareta20210409 common_user.js
// コメント：4~18行目までを抜粋。変数のスコープを修正
// ------------------------------

$(function(){
  let pagetop = $('#topBtn');
  pagetop.hide();
  $(window).scroll(function () {
    if ($(this).scrollTop() > 100) {
      pagetop.fadeIn();
    } else {
      pagetop.fadeOut();
    }
  });
  pagetop.click(function () {
    $('body, html').animate({ scrollTop: 0 }, 500);
    return false;
  });
});