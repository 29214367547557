// ------------------------------
// SPメニューOPEN時スクロールを停止
// ベース：hareta20210409 common_user.js
// コメント：22~27行目までを抜粋。変数のスコープを修正
// ------------------------------

$(function(){
  let menu_btn_sp=$('#drawer-check_sp');
  $(menu_btn_sp).on('click', function(){
      $('body').toggleClass('baned_scroll');
  });
});
